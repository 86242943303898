// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'name'
  }, {
    prop: 'description',
    span: 12
  }, {
    prop: 'price'
  }, {
    prop: 'hotel_room'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'online',
    back: true
  }]
}
